<template>
  <v-container>
    <v-snackbar
      v-model="snackbar.visible"
      :color="('type' in snackbar) ? snackbar.type : 'success'"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="('timeout' in snackbar) ? snackbar.timeout : 6000"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      <v-row>
        <v-icon
          class="px-3"
          dark
          large
        >
          {{ icon }}
        </v-icon>
        <!-- LOOK: {{ ('type' in snackbar) ? snackbar.type : 'success' }} -->
        <v-col>
          <div>
            <strong>{{ ('title' in snackbar) ? snackbar.title : title }}</strong>
          </div>
          <div v-html="snackbar.text" />
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          @click="snackbar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    name: 'SnackbarCard',

    props: {
      snackbar: {
        type: Object,
        default: () => ({
          type: undefined,
          mode: 'multi-line',
          timeout: 6000,
          title: undefined,
          text: undefined,
          visible: false,
          direction: undefined,
        }),
      },
    },
    computed: {
      // return garaneed direction
      parsedDirection () {
        if ('direction' in this.snackbar) {
          return this.snackbar.direction.split(' ')
        } else {
          return ['top', 'center']
        }
      },
      // return type with first uppercase letter
      title () {
        let myTitle = ''
        if ('type' in this.snackbar) {
          myTitle = this.snackbar.type.charAt(0).toUpperCase() + this.snackbar.type.slice(1)
        } else {
          myTitle = 'Success'
        }
        return myTitle
      },
      // Icon based on type
      icon () {
        let myicon = ''
        switch (this.snackbar.type) {
          case 'info':
            myicon = 'mdi-information'
            break
          case 'success':
            myicon = 'mdi-check-circle'
            break
          case 'warning':
            myicon = 'mdi-alert'
            break
          case 'error':
            myicon = 'mdi-minus-circle'
            break
          default:
            myicon = 'mdi-check-circle'
        }
        return myicon
      },
    },
  }
</script>

<style>
</style>
