<template>
  <div>
    <div class="mapouter">
      <!-- Leaflet Map -->
      <div class="gmap_canvas">
        <l-map
          :zoom="zoom"
          :center="center"
          :bounds="bounds"
          style="height: 100%; z-index: 0"
        >
          <l-icondefault />
          <l-tilelayer :url="mapTileServices.esriStreetMap" />
          <l-marker-cluster-pie
            ref="clusterRef"
            :options="clusterOptions"
            :key-func="keyFunc"
            :class-func="classFunc"
            :title-func="titleFunc"
            :style-func="styleFunc"
            :rmax="rmax"
            @clusterclick="click()"
          >
            <!-- add data to marker -->
            <l-marker
              v-for="marker in activeMarkers"
              :key="marker.id"
              :visible="marker.v"
              :lat-lng="marker.position"
              :icon="icon"
              :options="{ marker_data: marker }"
            >
              <l-popup :content="marker.id" />
            </l-marker>
          </l-marker-cluster-pie>
        </l-map>
      </div>

      <!-- Map overlay buttons -->
      <div id="map_buttons">
        <v-speed-dial
          v-model="fab"
          bottom
          right
          direction="left"
          open-on-hover
          transition="scale-transition"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              small
              color="blue darken-2"
              dark
              fab
            >
              <v-icon v-if="fab">
                mdi-layers
              </v-icon>
              <v-icon v-else>
                mdi-layers-outline
              </v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            x-small
            :color="histogrambuttoncolor"
            @click="hbcolor"
          >
            <v-icon>mdi-chart-bar</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            x-small
            :color="choicebuttoncolor"
            @click="cbcolor"
          >
            <v-icon>mdi-format-list-checkbox</v-icon>
          </v-btn>

          <v-btn
            fab
            dark
            x-small
            :color="drawerbuttoncolor"
            @click="drawerbutton"
          >
            <v-icon v-if="value">
              mdi-view-quilt
            </v-icon>
            <v-icon v-else>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </v-speed-dial>
      </div>

      <!-- Choices section -->
      <div
        v-if="choicevisible"
        id="legend"
      >
        <v-card
          class="overflow-auto"
          max-width="210"
          min-width="180"
          max-height="300"
          tile
        >
          <v-card-title>
            <v-select
              v-model="question"
              :items="dataSet"
              item-text="l"
              item-value="n"
              prepend-inner-icon="mdi-format-list-checkbox"
              dense
              single-line
              @change="dataSetSelected"
            />
          </v-card-title>
          <v-card-text>
            <v-row
              v-for="(choice, i) in choices.data"
              :key="i"
              class="text-lowercase mb-3"
            >
              <v-btn
                class="ml-3"
                text
                icon
                :color="choice.c"
                x-small
                @click="choiceUpdated(i, choice.v)"
              >
                <v-icon v-if="choice.v[0]">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-btn>
              <span
                m
                class="caption"
              >{{ choice.t[0] }}</span>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <!-- Histogram section -->
      <div
        v-if="histogramvisible"
        id="map_histogram"
      >
        <v-card
          class="mx-auto my-0"
          tile
          max-width="350"
          min-width="300"
        >
          <v-card-title>
            <v-select
              v-model="questionInteger"
              :items="dataSetInteger"
              item-text="l"
              item-value="n"
              prepend-inner-icon="mdi-chart-bar"
              dense
              single-line
              @change="prepHistogram"
            />
          </v-card-title>
          <chartist
            :data="histogram.data"
            :options="histogram.options"
            type="Bar"
            style="max-height: 170px;"
          />
        </v-card>
      </div>
    </div>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

  // import L from "leaflet";
  import SnackBarCard from '@/components/SnackbarCard'
  import { LMap, LTileLayer, LMarker, LPopup, LIconDefault, LControlLayers } from 'vue2-leaflet'
  // import L from 'leaflet'
  // import * as Vue2Leaflet from "vue2-leaflet";
  import { latLng, latLngBounds, Icon, icon } from 'leaflet'
  import LeafletMarkerclusterPie from './components/LeafletMarkerclusterPie'
  import iconUrl from 'leaflet/dist/images/marker-icon.png'
  import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  /*
  function rand (n) {
    const max = n + 0.1
    const min = n - 0.1
    return Math.random() * (max - min) + min
  }
  */

  /*
  // used for myIcon
  const myCustomColour = '#6f00ff'
  const markerHtmlStyles = `
    background-color: ${myCustomColour};
    width: 2rem;
    height: 2rem;
    display: block;
    left: -1rem;
    top: -1rem;
    position: relative;
    border-radius: 2rem 2rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`
    */

  export default {
    components: {
      SnackBarCard,
      'l-map': LMap,
      'l-tilelayer': LTileLayer,
      'l-icondefault': LIconDefault,
      'l-marker': LMarker,
      'l-popup': LPopup,
      'l-marker-cluster-pie': LeafletMarkerclusterPie,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      // default icon
      const customicon = icon(
        Object.assign({}, Icon.Default.prototype.options, {
          iconUrl,
          shadowUrl,
        }),
      )

      /*
      // myIcon insted of customicon
      const myIcon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`,
      })
      */

      return {
        question: '',
        myColors: [
          '#a6cee3',
          '#fdbf6f',
          '#b2df8a',
          '#33a02c',
          '#fb9a99',
          '#e31a1c',
          '#1f78b4',
          '#ff7f00',
          '#cab2d6',
          '#6a3d9a',
          '#ffff99',
          '#b15928',
          '#8dd3c7',
          '#ffffb3',
          '#bebada',
          '#fb8072',
          '#80b1d3',
          '#fdb462',
          '#b3de69',
          '#fccde5',
          '#d9d9d9',
          '#bc80bd',
          '#ccebc5',
          '#ffed6f',
        ],
        dataSet: [{ n: 'enum', l: 'Name of Enumerator' }],
        dataSetInteger: [{ n: 'age', l: 'Age' }],
        dialogNoData: false,
        zoom: 4,
        center: latLng(39.958852, 32.845815),
        bounds: null, // [[37, 33], [42, 38]],
        rmax: 35,
        iconStyleField: 'label',
        icon: customicon,
        clusterOptions: {},
        // initialLocation: latLng(-34.9205, -57.953646),
        colorMap: {},
        // selectedQuestion: {
        //   label: "Question 10",
        //   name: "q10"
        // },

        choicesFull: {
          enum: {
            question: 'Name of Enumerator',
            data: [
              { n: [1], l: ['Head of HH'], c: [''], v: [true] },
              { n: [2], l: ['Wife'], c: [''], v: [true] },
              { n: [3], l: ['Son'], c: [''], v: [true] },
              { n: [4], l: ['Daughter'], c: [''], v: [true] },
              { n: [5], l: ['Father'], c: [''], v: [true] },
              { n: [6], l: ['Mother'], c: [''], v: [true] },
            ],
          },
        },

        questionInteger: {
          age: {
            question: 'Age of HH member',
          },
        },

        choices: [],

        mapTileServices: {
          esriStreetMap: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          esriBaseMap: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          esriTopoMap: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          esriHillshade: 'http://services.arcgisonline.com/ArcGIS/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}',
          osmDefault: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
          openstreetmap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          openTopo: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          cartoDark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
          mapy: 'https://mapserver.mapy.cz/turist-m/{z}-{x}-{y}',
          googleMaps: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
          googleSatellite: 'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          googleHybrid: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
          googleTerrainDark: 'https://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}',
          googleRoads: 'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}',
        },

        markers: [
          {
            id: 'm0',
            position: { lat: 51.05, lng: 0.09 },
            v: false,
            agree: 'Yes',
            sq1: 'Wife',
            sq2: 33,
            sq3: 'Female',
            sq4: 4587.4,
          },
          {
            id: 'm1',
            position: { lat: 51.0, lng: -0.5 },
            v: true,
            agree: 'Yes',
            sq1: 'Head of HH',
            sq2: 44,
            sq3: 'Female',
            sq4: 5287.4,
          },
          {
            id: 'm8',
            position: { lat: 51.497, lng: 0.675 },
            v: true,
            agree: 'Yes',
            sq1: 'Father',
            sq2: 75,
            sq3: 'Male',
            sq4: 3950,
          },
        ],

        activeMarkers: [],

        direction: 'top',
        fab: true,
        fling: false,
        histogramvisible: false,
        histogrambuttoncolor: 'grey',
        choicevisible: true,
        choicebuttoncolor: 'grey',
        drawervisible: false,
        drawerbuttoncolor: 'black',

        // Latest data collection
        histogram: {
          data: {
            labels: [],
            series: [[2, 3, 4, 2]],
          },
          options: {
            low: 0,
            high: '5', // set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 2,
              right: 2,
              bottom: 12,
              left: 2,
            },
          },
        },

        // histogram data
        histData: [],
        foo: [50, 56, 87, 14, 2, 41, 65, 89, 25, 7, 14, 12, 11, 19, 54, 45, 63, 3, 55, 'kd'],

        // New snackbar notification
        snackBar: {
          type: 'success',
          mode: 'multi-line',
          direction: 'top center',
          timeout: 3000,
          title: '',
          text: '',
          visible: false,
        },
      }
    },

    computed: {

      ...mapState(['drawer']),
      // https://vuetifyjs.com/en/components/floating-action-buttons
      /*
      activeFab() {
        switch (this.tabs) {
          case "one":
            return { class: "purple", icon: "account_circle" };
          case "two":
            return { class: "red", icon: "edit" };
          case "three":
            return { class: "green", icon: "keyboard_arrow_up" };
          default:
            return {};
        }
      }
      */
    },

    watch: {
      top (val) {
        this.bottom = !val
      },
      right (val) {
        this.left = !val
      },
      bottom (val) {
        this.top = !val
      },
      left (val) {
        this.right = !val
      },
    },

    beforeDestroy () {
      this.$store.state.drawer = true
    },

    mounted () {
      // https://github.com/vuejs/awesome-vue
      // Download questions (select_one and integer) from ocpu and assign
      const main = this.$store.state.main
      const prop = {
        orgid: main.orgid,
        prjid: main.ap.prjid,
        formid: main.afid,
      }

      // Download data for select_one and integer questions and coordinates
      // from ocpu and assign markers
      this.$store.dispatch('ocpu/getLfltData', prop).then(response => {
        // console.log(response)
        this.markers = response

        this.activeMarkers = this.markers.slice()

        // Calculate bounds and center of the map
        const latmax = Math.max.apply(Math, this.activeMarkers.map(function (o) { return o.position.lat }))
        const latmin = Math.min.apply(Math, this.activeMarkers.map(function (o) { return o.position.lat }))
        const lngmax = Math.max.apply(Math, this.activeMarkers.map(function (o) { return o.position.lng }))
        const lngmin = Math.min.apply(Math, this.activeMarkers.map(function (o) { return o.position.lng }))
        this.center = latLng((latmax + latmin) / 2, (lngmax + lngmin / 2))
        this.bounds = latLngBounds(latLng(latmax, lngmax), latLng(latmin, lngmin))
        // console.log(`lat: ${latmax}-${latmin} lng: ${lngmax}-${lngmin}`)
      })

      // Download select_one and integer questions details
      this.$store.dispatch('ocpu/getLfltQuestion', prop).then(response => {
        this.choicesFull = response
        // console.log(response)
        const questSelect = []
        const questInt = []
        for (const [key, value] of Object.entries(response)) {
          if (value.type == 'integer') {
            const temp = {}
            temp.n = key
            temp.l = value.question[0]
            questInt.push(temp)
          } else {
            const temp = {}
            temp.n = key
            temp.l = value.question[0]
            questSelect.push(temp)
          }
        }
        // console.log(questSelect)
        this.dataSet = questSelect
        this.dataSetInteger = questInt

        // Loop questions until find data available
        let actQ
        let i = 0
        do {
          // filter array where question is exists in the data
          actQ = this.markers.filter(item => !!item[questSelect[i].n])
          i++
        }
        while (actQ.length === 0)
        this.question = questSelect[i].n
        this.dataSetSelected(this.dataSet[i].n)
      })
    },

    methods: {

      click: function (e) {
        // console.log('clusterclick')
      },

      keyFunc (d) {
        return d.options.marker_data[this.iconStyleField]
      },

      classFunc (d) {
        return 'cluster-marker-segment-color_' + d.data.key
      },

      // tooltip text on cluster pie
      titleFunc (d) {
        return `${d.data.key}, count: ${d.data.values.length}`
      },

      styleFunc (d) {
        const color = this.colorMap[d.data.key]
        return `
          fill: ${color};
          stroke: #444;
          background: ${color};
          border-color: #444;
        `
      },

      // One of the select one questions is selected
      dataSetSelected (question, update) {
        // console.log(question)
        // vue-randomcolor https://github.com/davidmerfield/randomColor#options
        // http://colorbrewer2.org/ https://gka.github.io/palettes/ http://vrl.cs.brown.edu/color
        if (question in this.choicesFull) {
          // console.log(this.choicesFull[`${question}`])
          this.choices = this.choicesFull[`${question}`]

          // Check dataset and alert if no data available for selected question
          this.activeMarkers = this.markers.filter(item => !!item[question])
          // console.log(this.activeMarkers.length)
          if (this.activeMarkers.length === 0) {
            this.snackBar = {
              type: 'info',
              mode: 'multi-line',
              text: `No survey data: '${question}'`,
              visible: true,
            }
          }

          // console.log(this.activeMarkers.length)
          const colorCount = this.choices.data.length
          let genColors = {}
          for (let i = 0; i < colorCount; i++) {
            const obj = {}
            const color = {}
            const c = this.choices.data[i].l
            obj[c] = this.myColors[i]
            this.choices.data[i].c = this.myColors[i]
            // console.log(this.choices.data[i].c[0]);
            genColors = Object.assign({}, genColors, obj)
          }
          this.iconStyleField = question
          this.colorMap = genColors
        } else {
          // this.iconStyleField = "v"
          // console.log('no choices')
        }
        // refresh Clusters to apply colors
        this.$refs.clusterRef.mapObject.refreshClusters()
      },

      // Choices in legend is updated
      choiceUpdated (i, prop) {
        // console.log(prop)
        // console.log(i)
        prop = prop[0]
        // console.log(prop)
        // console.log(this.choices.data[i])
        this.choices.data[i].v[0] = !prop
        // Loop all markers and...
        for (let j = 0; j < this.markers.length; j++) {
          // Change visibility accordingly if that's the changed choice
          if (this.markers[j][`${this.question}`] == this.choices.data[i].l) {
            this.markers[j].v = !prop
          }
        }
        // Update historgamm too
        this.prepHistogram(this.questionInteger)
      },

      // https://codepen.io/SitePoint/pen/LjVxym
      // https://revelry.co/histograms-functional-javascript/
      prepHistogram (question) {
        // console.log('prepHistogram da ' + question)
        this.histData = []
        this.histogram.data.labels = []
        this.histogram.data.series = [[]]
        const data = this.markers
        // console.log(data)
        // if (data.length !== 0) {
        // Collect all integer values into one array
        for (let i = 0, len = data.length; i < len; i++) {
          if (data[i].v) {
            // console.log(data[i])
            this.histData.push(Number(data[i][question]))
          }
        }
        // console.log(this.histData)
        // Prepare histogram data from integer array
        const histogramData = this.hist()
        // console.log(histogramData)
        // Update chart data
        for (let i = 0, len = histogramData.length; i < len; i++) {
          this.histogram.data.labels.push(histogramData[i].label)
          this.histogram.data.series[0].push(Number(histogramData[i].count))
        }
        this.histogram.options.high =
          Math.max(...this.histogram.data.series[0]) * 1.1
      // }
      },

      // Prepare histogram data object from selected integer question
      hist () {
        // https://stackoverflow.com/questions/37445495/binning-an-array-in-javascript-for-a-histogram/37451672

        let myData = this.histData
        // filter out all NaN, as dataset could have missing data
        myData = myData.filter(e => !isNaN(e))
        // console.log(myData)
        const bins = []

        const min = Math.min(...myData)
        // console.log(min)
        const max = Math.max(...myData)
        // if data length > 10 bucket = 10 else 1
        const bucketCount = Math.round(max - min) >= 10 ? 10 : 1

        const interval = Math.round((max - min) / bucketCount)
        // console.log(max + ' ' + min + ' ' + interval)

        // Setup Bins
        let binCount = 0
        for (let i = min; i < max; i += interval) {
          bins.push({
            minNum: i,
            maxNum: i + interval,
            label: `${i}-${i + interval}`,
            count: 0,
          })
          binCount++
        }

        // Loop through data and add to bin's count
        for (let i = 0; i < myData.length; i++) {
          const item = myData[i]
          for (let j = 0; j < bins.length; j++) {
            const bin = bins[j]
            if (item > bin.minNum && item <= bin.maxNum) {
              bin.count++
            }
          }
        }
        // console.log(bins)
        return bins
      },

      // Update choices button's color
      cbcolor () {
        // console.log(this.choicebuttoncolor);
        this.choicevisible = !this.choicevisible
        if (this.choicebuttoncolor == 'grey') {
          this.choicebuttoncolor = 'indigo'
        } else {
          this.choicebuttoncolor = 'grey'
        }
      },

      // Update historgam button's color
      hbcolor () {
        // console.log(this.histogrambuttoncolor);
        this.histogramvisible = !this.histogramvisible
        if (this.histogrambuttoncolor == 'grey') {
          this.histogrambuttoncolor = 'green'
        } else {
          this.histogrambuttoncolor = 'grey'
        }
      },

      // Update drawer button's state and color
      drawerbutton () {
        this.$store.commit('SET_DRAWER', !this.$store.state.drawer)
        if (this.drawerbuttoncolor == 'black') {
          this.drawerbuttoncolor = 'grey'
        } else {
          this.drawerbuttoncolor = 'black'
        }
      },

      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

    },

  }
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
html,
body {
  height: 100%;
  margin: 0;
}

#map_buttons {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 60px;
  margin: 5px;
  z-index: 99;
}

#wrapper {
  position: relative;
}

.mapouter {
  text-align: right;
  height: 100%;
  width: 100%;
  position: absolute;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}

#legend {
  position: absolute;
  top: 65px;
  right: 15px;
  margin: 0px;
  border-radius: 3px;
  z-index: 99;
}

#map_histogram {
  position: absolute;
  bottom: 20px;
  right: 15px;
  margin: 0px;
  z-index: 99;
}

.legendheading {
  position: relative;
  height: 5px;
  padding: 0px 2px 0px 2px;
  font-size: larger;
  font-weight: bold;
}

.legenditem {
  padding: 2px;
  margin-bottom: 2px;
}

/* For rotating chartist's labels on x-axis */
svg.ct-chart-bar, svg.ct-chart-line{
    overflow: visible;
}
.ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  font-size: 10px !important;
  transform-origin: 100% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space:nowrap;
}
/* 90* */
/*
.ct-label.ct-horizontal.ct-end {
   font-size: 10px !important;
   white-space:nowrap;
   writing-mode:vertical-rl;
   transform:  translateY(-10%);
 }
 */

/* This is for documentation purposes and will not be needed in your application */
/*
#create .v-speed-dial {
  position: absolute;
  z-index: 99;
}

#create .v-btn--floating {
  position: relative;
}*/
</style>
